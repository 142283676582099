
import { computed } from "vue";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useRoot } from "@libraryHelpers/compositionApi";
import { renderData } from "@libraryHelpers/dataComponents";

export default {
	name: "CoreBlockRichText",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const root = useRoot();
		const itemCheckFunction = (item) => item.type === "copy";
		const { isExpired } = useIsExpired(props.settings, itemCheckFunction);

		const replaceUrlBasePathInContent = (content) => {
			return renderData(
				content?.replace(
					/href=('|")[\/]/gi,
					`href="${root.$config["_app"].basePath}`
				) || "",
				props.dataSite
			);
		};

		const blockContent = computed(() => {
			return props.settings?.bodyText
				? replaceUrlBasePathInContent(props.settings.bodyText)
				: replaceUrlBasePathInContent(props.settings.richText);
		});

		return {
			isExpired,
			blockContent,
		};
	},
};
